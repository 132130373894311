import React from "react";
import App from "./App";
import "./index.css";
import AllCases from "./components/AllCases";
import AllTheme from "./components/AllTheme";
import { AddCase } from "./components/AddCase";
import { EditCase } from "./components/EditCase";
import VariableValueInputs from "./components/test3";
import OtherVariables from "./components/VariableComponent";
import AIG from "./components/AIG";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./components/logincomponents/context/AuthProvider";
import RequireAuth from "./RequireAuth";
import Login from "./components/logincomponents/login";
import { useEffect } from "react";
import axios from "./api/axios";
import { useContext } from "react";
import { GlobalContext } from "./context/GlobalContext";


const Application = () => {
  const { dispatch } = useContext(GlobalContext)
  useEffect(() => {
    axios
      .get("fetch_user_info/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        let obj = {
          username: res?.data?.username,
          image: res?.data?.image
        }
        dispatch({
          type: "USER_INFO",
          payload: obj
        })
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
        }
      });
  }, []);
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<RequireAuth />}>
              <Route path="/" element={<App />} />
              <Route path="/AIG" element={<AIG />} />
              <Route path="/themes/" element={<AllTheme />} />
              <Route path="/:theme/:id/cases" element={<AllCases />} />
              <Route path="/:theme/:id/addcase" element={<AddCase />} />
              <Route path="/:theme/:id/cases/edit/:case_name" element={<EditCase />} />
              <Route path="/test" element={<VariableValueInputs />} />
              <Route path="/realtest" element={<OtherVariables />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
};

export default Application;
