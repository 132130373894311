import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import useAuth from "./logincomponents/hooks/useAuth";
import axios from "../api/axios";
import { useContext } from "react";
import { GlobalContext } from "../context/GlobalContext";
import { useEffect } from "react";
export const Navigation = ({
  setShowModal,
  showModal,
  showFile,
  theme,
  themeName,
  showFiles,
  navType
}) => {
  const { state } = useContext(GlobalContext)
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [username, setUsername] = useState(state.user.username);
  const [profileImage, setProfileImage] = useState(state.user.image);
  useEffect(() => {
    setUsername(state.user.username)
    setProfileImage(state.user.image)
  }, [state.user])
  const handleFileClick = (event) => {
    event.target.value = null;
  };
  const logout = () => {
    axios
      .post(
        "logout/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setAuth({});
        window.localStorage.setItem("isLoggedIn", "false");
        navigate("/login");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
       });
  };
  return (
    <>
      <div className="d-lg-block d-md-block d-none">
        <div
          className="row align-items-center py-2"
          style={{
            border: "2px solid white",
            borderRight: "none",
            borderLeft: "none",
          }}
          id="headerBt"
        >
          <div className="d-flex justify-content-center col-lg-3 col-md-2">
            <div className="d-lg-block d-none">
              <div className="d-flex ">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  color="#fff"
                  className="pe-2 pt-1"
                />
                <p style={{ color: "white" }} className="mb-0">
                  issamfrancis@gmail.com
                </p>
              </div>
            </div>
          </div>

          <div className="d-lg-flex d-md-flex justify-content-around btn_group col-lg-6 col-md-8 px-xl-5 px-lg-4  d-lg-block d-md-block d-none">
            {navType === "filter" ? (
              <div
                style={{
                  textDecoration: "none",
                  width: "calc(100%)",
                  padding: "0 3rem",
                  margin: "4px 2px",
                }}
              >
                <Link
                  to="/"
                  className="bank"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "black",
                    padding: "0.4rem 3rem",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    width: "calc(100%)",
                    justifyContent: "center",
                  }}
                >
                  <label htmlFor="files" className="btn Go-to">
                    Open Automatic Item Generator (AIG)
                  </label>
                </Link>
              </div>
            ) : navType === "allcases" ? (
              <>
                <Link
                  to={`/${themeName}/${theme}/addcase`}
                  style={{
                    textDecoration: "none",
                    width: "calc(100%)",
                    padding: "0rem 3rem",
                    margin: "4px 2px",
                  }}
                >
                  <Button
                    className="bank"
                    style={{
                      textDecoration: "none",
                      alignItems: "center",
                      display: "flex",
                      backgroundColor: "whitesmoke",
                      color: "black",
                      padding: "0.375rem 0.75rem",
                      cursor: "pointer",
                      borderRadius: "3.2rem",
                      whiteSpace: "nowrap",
                      fontSize: "16px",
                      justifyContent: "center",
                    }}
                    component="label"
                  >
                    <span
                      style={{
                        padding: "0.2rem 0.75rem",
                        textTransform: "capitalize",
                      }}
                      className="d-flex align-items-center"
                    >
                      {" "}
                      <AddIcon className="me-1" /> Add Case
                    </span>
                  </Button>
                </Link>
              </>
            ) : navType === "addcase" ? (
              <div
                style={{
                  textDecoration: "none",
                  width: "calc(100%)",
                  padding: "0 3rem",
                  margin: "4px 2px",
                }}
              >
                <label
                  htmlFor="files"
                  className="btn bank"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "black",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    fontSize: "16px",
                    width: "calc(100%)",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ padding: "0.2rem 0.75rem" }}>
                    {" "}
                    Import Case Template
                  </span>
                </label>
                <input
                  id="files"
                  style={{ visibility: "hidden", display: "none" }}
                  type="file"
                  onChange={(e) => showFiles(e)}
                  onClick={handleFileClick}
                />
              </div>
            ) : navType === "home" ? (
              <>
                <Link
                  to="/AIG"
                  className="bank"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "black",
                    padding: "0.4rem 3rem",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    width: "calc(100% - 52%)",
                    justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor="files"
                    className="Go-to"
                    style={{ padding: "0.375rem 0.75rem" }}
                  >
                    Go To Question Bank
                  </label>
                </Link>
                <Link
                  to="/themes"
                  className="bank"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "black",
                    padding: "0.4rem 3rem",
                    margin: "4px 2px",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    width: "calc(100% - 52%)",
                    justifyContent: "center",
                  }}
                >
                  <label
                    htmlFor="files"
                    className="me-2 themes"
                    style={{ padding: "0.375rem 0.75rem" }}
                  >
                    Themes
                  </label>
                </Link>
              </>
            ) : navType === "allthemes" && (
              <>
                <Button
                  className="mb-0 bank"
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "#212529",
                    padding: "0.4rem 3rem",
                    margin: "0px 2px",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    width: "calc(100% - 52%)",
                    fontSize: "16px",
                    justifyContent: "center",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  color="success"
                  component="label"
                  id="add-theme"
                >
                  <span
                    style={{
                      padding: "0.2rem 0.75rem",
                      color: "#00000",
                      fontFamily: "'Roboto', sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    Import Theme
                  </span>
                  <input
                    id="files"
                    style={{ visibility: "hidden", display: "none" }}
                    type="file"
                    onChange={(e) => { showFile(e); console.log(e, 'e') }}
                    onClick={handleFileClick}

                  />
                </Button>

                <Button
                  className="mb-0 bank "
                  onClick={() => setShowModal(!showModal)}
                  style={{
                    textDecoration: "none",
                    alignItems: "center",
                    display: "flex",
                    backgroundColor: "whitesmoke",
                    color: "#212529",
                    padding: "0.4rem 3rem",
                    margin: "0px 2px",
                    cursor: "pointer",
                    borderRadius: "3.2rem",
                    whiteSpace: "nowrap",
                    width: "calc(100% - 52%)",
                    fontSize: "16px",
                    justifyContent: "center",
                    boxShadow: "none",
                  }}
                  variant="contained"
                  color="success"
                  component="label"
                  id="add-theme"
                >
                  <span
                    className="d-flex align-items-center"
                    style={{
                      padding: "0.2rem 0.75rem",
                      color: "#00000",
                      fontFamily: "'Roboto', sans-serif",
                      textTransform: "capitalize",
                    }}
                  >
                    {" "}
                    <AddIcon className="me-1" /> Add Theme
                  </span>
                </Button>
              </>
            )}
          </div>

          <div className="col-lg-3 col-md-2 d-lg-block d-md-block d-none">
            <div
              className="d-flex align-items-center justify-content-center"
              id="user"
            >
              <p
                style={{ color: "white" }}
                className="mb-0 d-lg-block d-none me-2"
              >
                {username}
              </p>
              <DropdownButton
                id="dropdown-item-button"
                title={<img src={profileImage} alt="" id="prImg" />}
              >
                <Dropdown.Item as="button" onClick={() => logout()}>
                  Sign out
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
        </div>
      </div>

      <div className="d-lg-flex d-md-flex justify-content-around btn_group col-lg-6 col-md-8 px-xl-5 px-lg-4  d-lg-none d-md-none d-block">
        {navType === "filter" ? (
          <>
            <Link
              to="/"
              className="bank"
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "black",
                padding: "0.4rem 3rem",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                width: "calc(100%)",
                justifyContent: "center",
              }}
            >
              <label htmlFor="files" className="btn themes">
                Open Automatic Item Generator (AIG)
              </label>
            </Link>
          </>
        ) : navType === "allcases" ? (
          <>
            <Link
              to={`/${themeName}/${theme}/addcase`}
              style={{
                textDecoration: "none",
                width: "calc(100%)",
                padding: "0.4rem 3rem",
                margin: "4px 2px",
              }}
            >
              <Button
                className="bank"
                style={{
                  textDecoration: "none",
                  alignItems: "center",
                  display: "flex",
                  backgroundColor: "whitesmoke",
                  color: "black",
                  cursor: "pointer",
                  borderRadius: "3.2rem",
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                  justifyContent: "center",
                }}
                component="label"
              >
                <span
                  style={{
                    padding: "0.2rem 0.75rem",
                    textTransform: "capitalize",
                  }}
                  className="d-flex align-items-center"
                >
                  {" "}
                  <AddIcon className="me-1" /> Add Case
                </span>
              </Button>
            </Link>
          </>
        ) : navType === "addcase" ? (
          <div
            className="mt-3"
            style={{
              width: "calc(100%)",
            }}
          >
            <label
              htmlFor="files"
              className="btn bank"
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "black",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                fontSize: "16px",
                width: "calc(100%)",
                justifyContent: "center",
              }}
            >
              <span style={{ padding: "0.2rem 0.75rem" }}>
                {" "}
                Import Case Template
              </span>
            </label>
            <input
              id="files"
              style={{ visibility: "hidden", display: "none" }}
              type="file"
              onChange={(e) => showFiles(e)}
              onClick={handleFileClick}
            />
          </div>
        ) : navType === "home" ? (
          <div className="d-flex">
            <Link
              to="/AIG"
              className="bank"
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "black",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                width: "calc(100% - 52%)",
                justifyContent: "center",
              }}
            >
              <label htmlFor="files" className="Go-to">
                Question Bank
              </label>
            </Link>

            <Link
              to="/themes"
              className="bank"
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "black",
                margin: "4px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                width: "calc(100% - 52%)",
                justifyContent: "center",
              }}
            >
              <label
                htmlFor="files"
                className="me-2 themes"
              >
                Themes
              </label>
            </Link>
          </div>
        ) : navType === "allthemes" && (
          <div className="d-flex my-2">
            <Button
              className="mb-0 bank"
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "#212529",
                margin: "0px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                width: "calc(100% - 52%)",
                fontSize: "16px",
                justifyContent: "center",
                boxShadow: "none",
              }}
              variant="contained"
              color="success"
              component="label"
              id="add-theme"
            >
              <span
                style={{
                  padding: "0.2rem 0.75rem",
                  color: "#00000",
                  fontFamily: "'Roboto', sans-serif",
                  textTransform: "capitalize",
                }}
              >
                Import Theme
              </span>
              <input
                id="files"
                style={{ visibility: "hidden", display: "none" }}
                type="file"
                onChange={(e) => showFile(e)}
                onClick={handleFileClick}
              />
            </Button>

            <Button
              className="mb-0 bank "
              onClick={() => setShowModal(!showModal)}
              style={{
                textDecoration: "none",
                alignItems: "center",
                display: "flex",
                backgroundColor: "whitesmoke",
                color: "#212529",
                margin: "0px 2px",
                cursor: "pointer",
                borderRadius: "3.2rem",
                whiteSpace: "nowrap",
                width: "calc(100% - 52%)",
                fontSize: "16px",
                justifyContent: "center",
                boxShadow: "none",
              }}
              variant="contained"
              color="success"
              component="label"
              id="add-theme"
            >
              <span
                className="d-flex align-items-center"
                style={{
                  padding: "0.2rem 0.75rem",
                  color: "#00000",
                  fontFamily: "'Roboto', sans-serif",
                  textTransform: "capitalize",
                }}
              >
                {" "}
                <AddIcon className="me-1" /> Add Theme
              </span>
            </Button>
          </div>
        )}
      </div>
    </>
  );
};
