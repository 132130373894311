import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ScrollButton from "./components/ScrollButton";
import Application from "./MainApp";
import { GlobalStateProvider } from "./context/GlobalState";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GlobalStateProvider>
      <Application />
      <ScrollButton />
    </GlobalStateProvider>
);
