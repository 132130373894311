import { useReducer } from "react";
import { GlobalContext } from "./GlobalContext";
import { reducer } from "./Reducer";

const initialState = {
    user: {
        username:"",
        image:""
       
    }
}
export const GlobalStateProvider = ({
    children,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <GlobalContext.Provider value={{ state, dispatch }}>
            {children}
        </GlobalContext.Provider>
    );
};