import {
  PlusOutlined,
  SafetyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Input, Button, Row, Col } from "antd";
import axios from "../api/axios";
import React from "react";
import { useState, useEffect, createRef } from "react";
import { Accordion, Breadcrumb, Form } from "react-bootstrap";
import { Dna } from "react-loader-spinner";
import Header from "./Header";
import QuestionInput from "../utils/QuestionInput";
import { useNavigate, useParams, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import OtherVariables from "./VariableComponent";
import CorrectCategoryOption from "./CorrectCategoryOption";
import DistractCategoryOption from "./DistractCategoryOption";
import LoadingBar from "react-top-loading-bar";
import CategorySectionEdit from "./CategorySectionEdit";
import Sidebar from "./Sidebar";
import { Navigation } from "./Navigation";
export const EditCase = (props) => {
  const HEADING = {
    addVariables: "Add Variables",
    age: "Age Range",
    ageInYears: "Age Range",
    ageInMonths: "Age Range",
    ageIndays: "Age Range",
    sex: "Gender",
    topic: "Topic",
    story: "Clinical Vignette/Scenario",
    variables: "Variables",
    questionCategories: "Question Categories",
    question: "Questions",
    Categories: "Add Categories",
    storyCategories: "Question Topic",
    Correct: "Correct Options",
    Correct1: "Correct Options",
    options: "Other Options",
    optionSection: "Distractor Options",
    previewButton: "Generate Items",
    generateButton: "Download TeX File",
    addQuestionCategory: "Add Question Category",
    addQuestion: "Add Question",
  };
  const { theme, id, case_name } = useParams();
  const [min, setMin] = useState("0");
  const [max, setMax] = useState("0");
  const [minMonths, setMinMonths] = useState("0");
  const [maxMonths, setMaxMonths] = useState("0");
  const [minYears, setMinYears] = useState("0");
  const [maxYears, setMaxYears] = useState("0");
  const [minWeeks, setMinWeeks] = useState("0");
  const [maxWeeks, setMaxWeeks] = useState("0");
  const [caseName, setCaseName] = useState(case_name);
  const [otherVariables, setOtherVariables] = useState([]);
  const [variableName, setVariableName] = useState("");
  const [existingValueError, setExistingValueError] = useState(false);
  const [inputVisible, setInputVisible] = useState({});
  const [chipInputRef, setChipInputRef] = useState({});
  const [progress, setProgress] = useState(30);
  const [showPage, setShowPage] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [categoriesFields, setCategoriesFields] = useState([]);
  const [question, setQuestion] = useState([]);
  const [questionCategoryValue, setQuestionCategoryValue] = useState([]);
  const [questionTagsValue, setQuestionTagsValue] = useState([]);
  const [questionInputRef] = useState({});
  const [tagfields] = useState({});
  const [answers, setAnswers] = useState({});
  const [correctMultipleOptions, setCorrectMultipleOptions] = useState({});
  const [genderSelect, setGenderSelect] = useState([]);
  const [correctMultipleOptionsInputRef, setCorrectMultipleOptionsInputRef] =
    useState({});
  const [answerInputRef, setAnswerInputRef] = useState({});
  const [boy, setBoy] = useState(false);
  const [girl, setGirl] = useState(false);
  const [imported, setImported] = useState(false);
  const [variables, setVariables] = useState([]);
  const [multiSelectValues, setMultiSelectValues] = useState([]);
  const [codesData, setCodesData] = useState();
  const [questionCategories, setQuestionCategories] = useState([]);
  const navigate = useNavigate();
  const themeName = theme?.replace(/%20/g, " ");
  const themeId = id?.replace(/%20/g, " ");

  useEffect(() => {
    axios.get("fetch-variables/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access")}`,
      },
      withCredentials: true,
    }).then((res) => {
      // setVariables(res?.data?.variables)
      const Data = res?.data?.variables?.map((item) => ({
        label: `${item.name} (${item.code})`,
        value: `${item.name} (${item.code})`,
      }));
      setCodesData(Data);
    }).catch(err => {
      if (err?.response?.status === 401) {
        console.log(err);
        window.localStorage.setItem("isLoggedIn", "false");
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    axios.post("view_case/", { id: themeId, case_name, }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access")}`,
      },
      withCredentials: true,
    }).then((res) => {
      let data = res?.data;
      let variablesRef = {};
      for (let i = 0; i < Object.keys(data?.other_variables_list).length; i++) {
        variablesRef[Object.keys(data?.other_variables_list)[i]] = [
          createRef(),
        ];
      }
      // correctoptionsref
      let correctOptionsInputRef = {};
      for (
        let i = 0;
        i < Object.keys(data?.correct_multiple_options).length;
        i++
      ) {
        let refsArray = [];
        for (
          let j = 0;
          j < Object.values(data?.correct_multiple_options)[i].length;
          j++
        ) {
          refsArray.push(createRef());
        }
        correctOptionsInputRef[Object.keys(data?.correct_multiple_options)[i]] =
          refsArray;
      }
      let answerInputOptionsRef = {};
      for (
        let i = 0;
        i < Object.keys(data?.distract_multiple_options).length;
        i++
      ) {
        let refsArray = [];
        for (
          let j = 0;
          j < Object.values(data?.distract_multiple_options)[i].length;
          j++
        ) {
          refsArray.push(createRef());
        }
        answerInputOptionsRef[Object.keys(data?.distract_multiple_options)[i]] =
          refsArray;
      }
      let genders = []
      if (data?.gender?.find((elem) => elem === "male")) {
        setBoy(true);
        genders.push("male")
      }
      if (data?.gender?.find((elem) => elem === "female")) {
        setGirl(true);
        genders.push("female")
      }
      if (data?.gender?.find((elem) => elem === "male") && data?.gender?.find((elem) => elem === "female")) {
        genders.push("both")
      }
      setImported(true);
      setGenderSelect(genders);
      setCorrectMultipleOptionsInputRef(correctOptionsInputRef);
      setChipInputRef(variablesRef);
      if (data?.ages?.Day) {
        setMin(data?.ages?.Day[0] ?? "0");
        setMax(data?.ages?.Day[1] ?? "0");
      }
      if (data?.ages?.Week) {
        setMaxWeeks(data?.ages?.Week[1] ?? "0");
        setMinWeeks(data?.ages?.Week[0] ?? "0");
      }
      if (data?.ages?.Month) {
        setMinMonths(data?.ages?.Month[0] ?? "0");
        setMaxMonths(data?.ages?.Month[1] ?? "0");
      }
      if (data?.ages?.Year) {
        setMinYears(data?.ages?.Year[0] ?? "0");
        setMaxYears(data?.ages?.Year[1] ?? "0");
      }

      setMultiSelectValues(Object.keys(data?.other_variables_list));
      setOtherVariables(data?.other_variables_list);
      setVariables(data?.variables);
      setCategoriesFields(data?.categories_fields);
      setQuestion(data?.questions);
      setQuestionCategoryValue(data?.question_category_values);
      setCorrectMultipleOptions(data?.correct_multiple_options);
      setAnswers(data?.distract_multiple_options);
      setAnswerInputRef(answerInputOptionsRef);
      setProgress(100);
      setShowPage(true);
      // setImported(false)
    }).catch(err => {
      if (err?.response?.status === 401) {
        console.log(err);
        window.localStorage.setItem("isLoggedIn", "false");
        navigate("/login");
      }
    });
  }, []);

  useEffect(() => {
    if (imported === true) {
      axios
        .get("question_category_mcq_list/", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("access")}`,
          },
          withCredentials: true,
        })
        .then((res) => {
          let array = questionCategoryValue?.filter(
            (value, index, array) => array.indexOf(value) === index
          );
          let arr = res?.data;
          for (let i = 0; i < array.length; i++) {
            let index = arr.findIndex(
              (elem) => elem?.mcq_category === array[i]
            );
            // console.log(index, 'index')
            if (arr[index]) {
              arr[index].selected = true;
            }
          }
          setQuestionCategories(arr);
          setProgress(100);
          setShowPage(true);
          setImported(false);
        })
        .catch((err) => {
          if (err?.response?.status === 401) {
            console.log(err);
            window.localStorage.setItem("isLoggedIn", "false");
            navigate("/login");
          }
        });
    }
  }, [questionCategoryValue]);

  const handleAddVariableForMultiSelect = () => {
    for (let i = 0; i < multiSelectValues.length; i++) {
      if (
        multiSelectValues[i] !== "" &&
        !Object.keys(otherVariables)?.includes(multiSelectValues[i])
      ) {
        setOtherVariables((prevValues) => {
          let obj = { ...prevValues, [multiSelectValues[i]]: [] }
          const sortedData = {};
          multiSelectValues.forEach(key => {
            sortedData[key] = obj[key];
          });
          return sortedData;
        });
        setChipInputRef((prevRefs) => {
          let obj = { ...prevRefs, [multiSelectValues[i]]: [createRef()] }
          const sortedRefs = {};
          multiSelectValues.forEach(key => {
            sortedRefs[key] = obj[key];
          });
          return sortedRefs;
        });
      }
    }
    for (let j = 0; j < Object.keys(otherVariables)?.length; j++) {
      if (!multiSelectValues.includes(Object.keys(otherVariables)[j])) {
        let key = Object.keys(otherVariables)[j];
        const newState = { ...otherVariables };
        delete newState[key];
        setOtherVariables(newState);
        const tempState = { ...chipInputRef };
        delete tempState[key];
        setChipInputRef(tempState);
      }
    }
  };

  const genderSelectFunction = (gen, str) => {
    var array = [...genderSelect];
    var index = array.indexOf(str);
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(str);
    }

    if (array.includes("male") && array.includes("female")) {
      if (!array.includes("both")) {
        array.push("both");
      }
    } else {
      var bothIndex = array.indexOf("both");
      if (bothIndex !== -1) {
        array.splice(bothIndex, 1);
      }
    }
    let obj1 = { ...correctMultipleOptions }
    let obj2 = { ...answers }
    if (array.includes('both')) {
      for (const category in obj1) {
        obj1[category].forEach(item => {
          item.gender = "both";
        });
      }
      for (const category in obj2) {
        obj2[category].forEach(item => {
          item.gender = "both";
        });
      }
    } else {
      for (const category in obj1) {
        obj1[category].forEach(item => {
          item.gender = array[0];
        });
      }
      for (const category in obj2) {
        obj2[category].forEach(item => {
          item.gender = array[0];
        });
      }
    }

    setCorrectMultipleOptions(obj1);
    setAnswers(obj2);
    setGenderSelect(array);

  };

  function validCase() {
    let validCorrectOption;
    let validIncorrectOption;
    for (let i = 0; i < categoriesFields?.length; i++) {
      let totalNumberOfCorrectOptionsForMale = correctMultipleOptions[categoriesFields[i]]?.filter(elem => elem.gender === "male" && elem.title !== "")
      let totalNumberOfCorrectOptionsForFemale = correctMultipleOptions[categoriesFields[i]]?.filter(elem => elem.gender === "female" && elem.title !== "")
      let totalNumberOfCorrectOptionsForBoth = correctMultipleOptions[categoriesFields[i]]?.filter(elem => elem.gender === "both" && elem.title !== "")
      if (totalNumberOfCorrectOptionsForBoth?.length > 0) {
        validCorrectOption = true;
      } else {
        if (totalNumberOfCorrectOptionsForMale?.length > 0 && totalNumberOfCorrectOptionsForFemale?.length > 0) {
          validCorrectOption = true;
        } else {
          validCorrectOption = false;
        }
      }
      let totalNumberOfInCorrectOptionsForMale = answers[categoriesFields[i]]?.filter(elem => elem.gender === "male" && elem.title !== "")
      let totalNumberOfInCorrectOptionsForFemale = answers[categoriesFields[i]]?.filter(elem => elem.gender === "female" && elem.title !== "")
      let totalNumberOfInCorrectOptionsForBoth = answers[categoriesFields[i]]?.filter(elem => elem.gender === "both" && elem.title !== "")
      if (totalNumberOfInCorrectOptionsForBoth?.length > 3) {
        validIncorrectOption = true;
      } else {
        if (totalNumberOfInCorrectOptionsForMale?.length > 3 && totalNumberOfInCorrectOptionsForFemale?.length > 3) {
          validIncorrectOption = true;
        } else {
          validIncorrectOption = false;
        }
      }
      if (!validCorrectOption || !validIncorrectOption) {
        return { status: false, categoryName: categoriesFields[i] }
      }
    }
    return { status: true }
  }

  function oneGenderCase(g) {
    let validCorrectOption;
    let validIncorrectOption;
    for (let i = 0; i < categoriesFields?.length; i++) {
      let totalNumberOfCorrectOptions = correctMultipleOptions[
        categoriesFields[i]
      ]?.filter((elem) => elem.gender === g && elem.title !== "");
      if (totalNumberOfCorrectOptions?.length > 0) {
        validCorrectOption = true;
      } else {
        validCorrectOption = false;
      }
      let totalNumberOfInCorrectOptions = answers[categoriesFields[i]]?.filter(
        (elem) => elem.gender === g && elem.title !== ""
      );
      if (totalNumberOfInCorrectOptions?.length > 3) {
        validIncorrectOption = true;
      } else {
        validIncorrectOption = false;
      }
      if (!validCorrectOption || !validIncorrectOption) {
        return { status: false, categoryName: categoriesFields[i] };
      }
    }
    return { status: true };
  }

  useEffect(() => {
    if (genderSelect?.length === 1) {
      let g = genderSelect[0];
      const newData = { ...correctMultipleOptions };
      for (const [key, value] of Object.entries(newData)) {
        newData[key] = value.map((item) => {
          return { ...item, gender: g };
        });
      }
      setCorrectMultipleOptions(newData);
      const newData1 = { ...answers };
      for (const [key, value] of Object.entries(newData1)) {
        newData1[key] = value.map((item) => {
          return { ...item, gender: g };
        });
      }
      // setCorrectMultipleOptions(newData);
      setAnswers(newData1);
    }
  }, [genderSelect]);

  function HandleEditCase() {
    let selectedGenders = [];
    if (boy) {
      selectedGenders.push("male");
    }
    if (girl) {
      selectedGenders.push("female");
    }

    // console.log("Selected Genders:", selectedGenders);

    const isGenderChanged =
      JSON.stringify(selectedGenders) !== JSON.stringify(genderSelect);

    if (!isGenderChanged) {
      // console.log("No changes in gender selection");
      sendEditRequest(selectedGenders);
      return;
    }

    let invalidCase;
    console.log("selectedGenders", selectedGenders)
    if (selectedGenders.length > 0) {
      if (selectedGenders.length === 1) {
        console.log(answers, "answers");
        invalidCase = oneGenderCase(selectedGenders[0]);
      } else {
        invalidCase = validCase();
      }

      if (!invalidCase?.status) {
        console.log("Invalid Case Data:", invalidCase);
        alert(
          `Please make sure you have added options correctly against ${invalidCase?.categoryName}`
        );
        return;
      }
    }

    sendEditRequest(selectedGenders);
  }

  function sendEditRequest(selectedGenders) {
    let listOfVariables = {};
    for (let i = 0; i < Object.keys(otherVariables)?.length; i++) {
      let item =
        (Object.keys(otherVariables)[i] || "")
          .trim()
          .match(/\([a-z0-9_-]+\)/gi)?.[0] || "";
      listOfVariables[item.slice(1, item.length - 1)] =
        Object.values(otherVariables)[i];
    }

    let body = {
      topic_id: id,
      caseName: caseName,
      ages: {
        Day: [parseInt(min === "" ? "0" : min), parseInt(max === "" ? "0" : max)],
        Week: [parseInt(minWeeks === "" ? "0" : minWeeks), parseInt(maxWeeks === "" ? "0" : maxWeeks)],
        Month: [parseInt(minMonths === "" ? "0" : minMonths), parseInt(maxMonths === "" ? "0" : maxMonths)],
        Year: [parseInt(minYears === "" ? "0" : minYears), parseInt(maxYears === "" ? "0" : maxYears)],
      },
      gender: selectedGenders,
      variables: listOfVariables,
      categoriesFields: categoriesFields,
      questions: question,
      questionCategoryValue: questionCategoryValue,
      option: answers,
      correctMultipleOptions: correctMultipleOptions,
    };

    axios
      .post("edit_case/", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${window.localStorage.getItem("access")}`,
        },
        withCredentials: true,
      })
      .then((res) => {
        alert("Case has been saved successfully");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  }

  function handleClick(event, link) {
    if (event.ctrlKey) {
      event.preventDefault(); // Prevents the default behavior of opening the link in the same tab
      window.open(link, "_blank"); // Opens the link in a new tab
    } else {
      if (link.includes("cases/")) {
        console.log(link.replace("cases/", ""), "replaced one");
        navigate(link.replace("cases/", ""));
      } else {
        navigate(link);
      }
    }
  }

  const handleAddVariable = () => {
    setExistingValueError(false);
    if (Object.keys(otherVariables).find((elem) => elem === variableName)) {
      setExistingValueError(true);
    } else {
      setOtherVariables((prevValues) => {
        return { ...prevValues, [variableName]: [] };
      });
      setVariableName("");
      setChipInputRef((prevRefs) => {
        return { ...prevRefs, [variableName]: [createRef()] };
      });
    }
  };

  const onChipInputChange = (field, newVal) => {
    const newInputValue = { ...inputValue };
    newInputValue[field] = newVal;
    setInputValue(newInputValue);
  };

  const onChipInputToggleVisb = (field, visible) => {
    const newInputVisible = { ...inputVisible };
    newInputVisible[field] = visible;
    setInputVisible(newInputVisible);
    setTimeout(() => {
      const inputRef = chipInputRef[field][0];
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const onChipInputSubmit = (field) => {
    let newFields = otherVariables[field];
    if (
      inputValue[field] &&
      otherVariables[field].indexOf(inputValue[field]) === -1
    ) {
      newFields = otherVariables[field].concat(
        (inputValue[field] || "").trim()
      );
    }
    const newFullFields = { ...otherVariables };
    newFullFields[field] = newFields;
    setOtherVariables(newFullFields);
    const newInputValue = { ...inputValue };
    newInputValue[field] = "";
    setInputValue(newInputValue);
    setInputVisible((prevState) => ({
      ...prevState,
      ...(inputValue[field] ? {} : { [field]: false }),
    }));
  };

  const onChipRemove = (field, tag) => {
    const newFullFields = { ...otherVariables };
    newFullFields[field] = otherVariables[field].filter((el) => el !== tag);
    setOtherVariables(newFullFields);
  };

  const addCategory = (questionCategories) => {
    const newFields = [...categoriesFields];
    const newAnswers = { ...answers };
    const newCMO = { ...correctMultipleOptions };
    const newCorrectMultipleOptionsInputRef = {
      ...correctMultipleOptionsInputRef,
    };
    const newAnswerInputRef = { ...answerInputRef };

    for (let i = 0; i < questionCategories.length; i++) {
      const category = questionCategories[i];
      const value = category?.mcq_category;

      if (category?.selected && value) {
        if (!newFields.includes(value)) {
          newFields.push(value);
          newAnswers[value] = [{}];
          newCMO[value] = [{}];
          newCorrectMultipleOptionsInputRef[value] = [createRef()];
          newAnswerInputRef[value] = [createRef()];
        } else {
          continue;
        }
      }
    }

    setCategoriesFields(newFields);
    setAnswers(newAnswers);
    setCorrectMultipleOptions(newCMO);
    setCorrectMultipleOptionsInputRef(newCorrectMultipleOptionsInputRef);
    setAnswerInputRef(newAnswerInputRef);
  };

  const onAddQuestion = (questionCategories) => {
    let tempQuestions = [];
    let tempCategoryValues = [];
    for (let i = 0; i < questionCategories.length; i++) {
      if (questionCategories[i]?.selected === true) {
        let tempCategory = questionCategories[i]?.mcq_category;
        for (
          let j = 0;
          j < questionCategories[i]?.results_question?.length;
          j++
        ) {
          let tempQuestion =
            questionCategories[i]?.results_question[j]?.question;
          if (!tempQuestions.includes(tempQuestion)) {
            tempQuestions.push(tempQuestion);
            tempCategoryValues.push(tempCategory);
          }
        }
      }
    }
    setQuestion(tempQuestions);
    setQuestionCategoryValue(tempCategoryValues);
  };

  const questionTagsValueChangefun = (index, val) => {
    let tagValue = [...questionTagsValue];
    tagValue = [val];
    setQuestionTagsValue(tagValue);
  };

  const questionCategoryValueChangefun = (index, val) => {
    let questionCategories = [...questionCategoryValue];
    questionCategories[index] = val;
    setQuestionCategoryValue(questionCategories);
  };

  const onQuestionInputSaveRef = (index, ref) => {
    questionInputRef[index] = ref;
  };

  function removeElAtIndex(arr, index) {
    return arr.slice(0, index).concat(arr.slice(index + 1));
  }

  function getOccurrence(array, value) {
    return array.filter((v) => v === value).length;
  }

  const onQuestionRemove = (index) => {
    let category = questionCategoryValue[index];
    setQuestion(removeElAtIndex(question, index));
    setQuestionCategoryValue(removeElAtIndex(questionCategoryValue, index));
    const occurence = getOccurrence(questionCategoryValue, category);
    if (occurence === 1) {
      const categoryIndex = categoriesFields.indexOf(category);
      setCategoriesFields(removeElAtIndex(categoriesFields, categoryIndex));
      const target = { ...answers };
      delete target[category];
      setAnswers(target);
      const target1 = { ...correctMultipleOptions };
      delete target1[category];
      setCorrectMultipleOptions(target1);
    }
  };

  const onQuestionInputChange = (index, newVal) => {
    const newQuestion = question.slice();
    newQuestion[index] = newVal;
    setQuestion(newQuestion);
  };

  const onCorrectMultipleOptionsInputSaveRef = (index, ref) => {
    correctMultipleOptionsInputRef[index] = ref;
  };

  function removeOptionElAtIndex(arr, category, index) {
    arr[category].splice(index, 1);
    return arr;
  }

  const onCorrectMultipleOptionsRemove = (category, index) => {
    const newArr = { ...correctMultipleOptions };
    newArr?.[category]?.splice(index, 1);
    setCorrectMultipleOptions(newArr);
    setCorrectMultipleOptionsInputRef(
      removeOptionElAtIndex(correctMultipleOptionsInputRef, category, index)
    );
  };

  const onCorrectMultipleOptionsInputChange = (category, index, e) => {
    const newAnswers = { ...correctMultipleOptions };
    const { name, value } = e.target;
    newAnswers[category][index] = {
      ...newAnswers?.[category][index],
      [name]: value,
    };
    setCorrectMultipleOptions(newAnswers);
  };

  const onCorrectMultipleOptionsInputAdd = (index, category) => {
    let newRefs;
    setCorrectMultipleOptionsInputRef((prevRefs) => {
      newRefs = [...prevRefs[category], createRef()];
      return { ...prevRefs, [category]: newRefs };
    });
    const newCorrectMultipleOptions = { ...correctMultipleOptions };
    const newArr = newCorrectMultipleOptions[category];
    newArr.push({});
    newCorrectMultipleOptions[category] = newArr;
    setCorrectMultipleOptions(newCorrectMultipleOptions);
    setTimeout(() => {
      const inputRef = newRefs[newRefs.length - 1];
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const onCorrectMultipleOptionsLoad = (category, index, newVal, key) => {
    const newAnswers = { ...correctMultipleOptions };
    newAnswers[category][index][key] = newVal;
    setCorrectMultipleOptions(newAnswers);
  };

  const onAnswerInputSaveRef = (index, ref) => {
    answerInputRef[index] = ref;
  };

  const onAnswerRemove = (category, index) => {
    const newArr = { ...answers };
    newArr?.[category]?.splice(index, 1);
    setAnswers(newArr);
    setAnswerInputRef(removeOptionElAtIndex(answerInputRef, category, index));
  };

  const onAnswersInputChange = (category, index, e) => {
    const newAnswers = { ...answers };
    const { name, value } = e.target;
    newAnswers[category][index] = {
      ...newAnswers?.[category][index],
      [name]: value,
    };
    setAnswers(newAnswers);
  };

  const onAnswersInputAdd = (index, category) => {
    const newAnswers = { ...answers };
    const newArr = newAnswers[category];
    newArr.push({});
    newAnswers[category] = newArr;
    setAnswers(newAnswers);
    let newRefs;
    setAnswerInputRef((prevRefs) => {
      newRefs = [...prevRefs[category], createRef()];
      return { ...prevRefs, [category]: newRefs };
    });
    setTimeout(() => {
      const inputRef = newRefs[newRefs.length - 1];
      if (inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
  };

  const onAnswersLoad = (category, index, newVal, key) => {
    const newAnswers = { ...answers };
    newAnswers[category][index][key] = newVal;
    setAnswers(newAnswers);
  };

  function downloadFile() {
    let str = {
      imported: imported,
      caseName: caseName,
      theme: id,
      min: min,
      max: max,
      minWeeks,
      maxWeeks,
      minMonths,
      maxMonths,
      minYears,
      maxYears,
      otherVariables: otherVariables,
      categoriesFields: categoriesFields,
      question: question,
      questionCategoryValue: questionCategoryValue,
      correctMultipleOptions: correctMultipleOptions,
      answers: answers,
      genderSelect: genderSelect,
    };
    const blob = new Blob([JSON.stringify(str)], { type: "application/js" });
    saveAs(blob, `${caseName}.conf`);
  }

  const HandleAddMoreQuestions = (category) => {
    const index = questionCategoryValue.lastIndexOf(category) + 1;
    let questionsArray = [...question];
    let categoryValueArray = [...questionCategoryValue];
    questionsArray.splice(index, 0, "");
    categoryValueArray.splice(index, 0, category);
    setQuestionCategoryValue(categoryValueArray);
    setQuestion(questionsArray);
  };

  useEffect(() => {
    handleAddVariableForMultiSelect();
  }, [multiSelectValues]);

  return (
    <>
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
      />
      {showPage && (
        <main className="App">
          <Sidebar />
          <Header name="Automatic Item Generator v.4 (AIG-4)" />
          <Navigation themeName={themeName} theme={themeId} />
          <Row className="mt-5">
            <Col flex={1} className="d-flex align-items-end">
              <Breadcrumb>
                <Breadcrumb.Item
                  onClick={(event) => handleClick(event, "/")}
                  style={{ fontSize: "1.2rem" }}
                >
                  <Link
                    to={"/"}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    MCQs Generator
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={(event) => handleClick(event, "/themes")}
                  style={{ fontSize: "1.2rem" }}
                >
                  <Link
                    to={"/themes"}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    Themes
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={(event) => handleClick(event, `/${themeName}/${themeId}/cases`)}
                  style={{ fontSize: "1.2rem" }}
                >
                  <Link
                    to={`/${themeName}/${themeId}/cases`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {themeName}
                  </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item
                  active
                  style={{ fontSize: "1.2rem", color: "white" }}
                >
                  {caseName}
                </Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
          {!id ? (
            <center>
              <Dna
                visible={true}
                height="60"
                width="80"
                ariaLabel="dna-loading"
                wrapperStyle={{}}
                wrapperClass="dna-wrapper"
                style={{ color: "white" }}
              />
            </center>
          ) : (
            <>
              <div
                className="d-flex align-items-center row"
                style={{ marginTop: "1.5rem" }}
              >
                <div className="my-4 col-lg-4 col-md-6 col-12 d-flex align-items-center">
                  <b>Case Name:&nbsp;&nbsp;</b>
                  <Input
                    className="onDisabled"
                    style={{ width: "63%" }}
                    placeholder="Case Name"
                    name={caseName}
                    value={caseName}
                    onChange={(e) => setCaseName(e.target.value)}
                    disabled
                  />
                </div>
                <div className="my-4 col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-lg-center justify-content-md-center ">
                  {/* <b level={4}>{HEADING.age}:</b>
                  &nbsp;&nbsp;
                  <Input
                    type="number"
                    min={0}
                    max={max}
                    style={{ width: "50px" }}
                    placeholder={"min"}
                    name={min}
                    value={min}
                    onChange={(e) => setMin(e.target.value)}
                  />
                  &nbsp;
                  <span>
                    <big>-</big>
                  </span>
                  &nbsp;
                  <Input
                    type="number"
                    style={{ width: "50px" }}
                    placeholder={"max"}
                    min={min}
                    max={999}
                    name={max}
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                  /> */}
                </div>
                <div className="my-4 col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-lg-end">
                  <div>
                    <b level={4}>{HEADING.sex}:&nbsp;</b>
                    <div style={{ display: "inline-block" }}>
                      <Form.Check
                        aria-label={`category 1`}
                        checked={genderSelect.includes("male")}
                        className="mx-1"
                        onChange={() => {
                          genderSelectFunction(
                            !genderSelect.includes("male"),
                            "male"
                          );
                          setBoy(!boy)
                        }}
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      &nbsp;{"male"}&nbsp;&nbsp;
                    </div>
                    <div style={{ display: "inline-block" }}>
                      <Form.Check
                        aria-label={`category 1`}
                        checked={genderSelect.includes("female")}
                        className="mx-1"
                        onChange={() => {
                          genderSelectFunction(
                            !genderSelect.includes("female"),
                            "female"
                          );
                          setGirl(!girl)
                        }}
                      />
                    </div>
                    <div style={{ display: "inline-block" }}>
                      &nbsp;{"female"}&nbsp;&nbsp;
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4 row case-aig justify-content-between">
                <div className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start">
                  <b level={4}>{HEADING.ageIndays}:</b>
                  &nbsp;&nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"min"}
                    name={min}
                    value={min === "0" || min === 0 ? "" : min}
                    onChange={(e) => setMin(e.target.value)}
                  />
                  &nbsp;
                  {/* <span style={{ marginTop: "5px" }}> */}
                  <big>-</big>
                  {/* </span> */}
                  &nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"max"}
                    name={max}
                    value={max === "0" || max === 0 ? "" : max}
                    onChange={(e) => setMax(e.target.value)}
                  />
                  <div className="mx-2"><b>in</b></div>
                  <Input
                    value={"DAYS"}
                    style={{ width: 100, textAlign: "center" }}
                  />
                </div>
                <div className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start">
                  <b level={4}>{HEADING.ageInMonths}:</b>
                  &nbsp;&nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"min"}
                    name={minWeeks}
                    value={minWeeks === "0" || minWeeks === 0 ? "" : minWeeks}
                    onChange={(e) => setMinWeeks(e.target.value)}
                  />
                  &nbsp;
                  {/* <span style={{ marginTop: "5px" }}> */}
                  <big>-</big>
                  {/* </span> */}
                  &nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"max"}
                    name={maxWeeks}
                    value={maxWeeks === "0" || maxWeeks === 0 ? "" : maxWeeks}
                    onChange={(e) => setMaxWeeks(e.target.value)}
                  />
                  <div className="mx-2"><b>in</b></div>
                  <Input
                    value={"WEEKS"}
                    style={{ width: 100, textAlign: "center" }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center row case-aig">
                <div className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start">
                  <b level={4}>{HEADING.ageInMonths}:</b>
                  &nbsp;&nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"min"}
                    name={minMonths}
                    value={minMonths === "0" || minMonths === 0 ? "" : minMonths}
                    onChange={(e) => setMinMonths(e.target.value)}
                  />
                  &nbsp;
                  {/* <span style={{ marginTop: "5px" }}> */}
                  <big>-</big>
                  {/* </span> */}
                  &nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"max"}
                    name={maxMonths}
                    value={maxMonths === "0" || maxMonths === 0 ? "" : maxMonths}
                    onChange={(e) => setMaxMonths(e.target.value)}
                  />
                  <div className="mx-2"><b>in</b></div>
                  <Input
                    value={"MONTHS"}
                    style={{ width: 100, textAlign: "center" }}
                  />
                </div>
                <div className="my-4 col-lg-12 col-md-12 col-12 d-flex align-items-center justify-content-start">
                  <b level={4}>{HEADING.ageInYears}:</b>
                  &nbsp;&nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"min"}
                    name={minYears}
                    value={minYears === "0" || minYears === 0 ? "" : minYears}
                    onChange={(e) => setMinYears(e.target.value)}
                  />
                  &nbsp;
                  {/* <span style={{ marginTop: "5px" }}> */}
                  <big>-</big>
                  {/* </span> */}
                  &nbsp;
                  <Input
                    style={{ width: "50px" }}
                    placeholder={"max"}
                    name={maxYears}
                    value={maxYears === "0" || maxYears === 0 ? "" : maxYears}
                    onChange={(e) => setMaxYears(e.target.value)}
                  />
                  <div className="mx-2"><b>in</b></div>
                  <Input
                    value={"YEARS"}
                    style={{ width: 100, textAlign: "center" }}
                  />
                </div>
              </div>
              <OtherVariables
                multiSelectValues={multiSelectValues}
                setMultiSelectValues={setMultiSelectValues}
                codesData={codesData}
                variables={variables}
                otherVariables={otherVariables}
                setOtherVariables={setOtherVariables}
                chipInputRef={chipInputRef}
                setChipInputRef={setChipInputRef}
                variableName={variableName}
                setVariableName={setVariableName}
                inputValue={inputValue}
                setInputValue={setInputValue}
                inputVisible={inputVisible}
                setInputVisible={setInputVisible}
                existingValueError={existingValueError}
                setExistingValueError={existingValueError}
                handleAddVariable={handleAddVariable}
                onChipInputChange={onChipInputChange}
                onChipInputToggleVisb={onChipInputToggleVisb}
                onChipInputSubmit={onChipInputSubmit}
                onChipRemove={onChipRemove}
              />
              <div className="my-4">
                <CategorySectionEdit
                  onAddQuestion={onAddQuestion}
                  addCategory={addCategory}
                  questionCategories={questionCategories}
                  setQuestionCategories={setQuestionCategories}
                />
              </div>
              {questionCategoryValue
                ?.filter(
                  (value, index, array) => array.indexOf(value) === index
                )
                .map((category, index) => {
                  return (
                    <Accordion
                      key={index}
                      defaultActiveKey={index === 0 ? "0" : ""}
                      className="mb-3"
                    // style={{ borderRadius: "0.5rem" }}
                    >
                      <Accordion.Item
                        eventKey={`${index}`}
                        style={{ borderRadius: "0.5rem" }}
                      >
                        <Accordion.Header>
                          <h3
                            className="text-center m-0"
                            style={{ fontSize: "19px", fontWeight: "700" }}
                          >
                            {category}
                          </h3>
                        </Accordion.Header>
                        <Accordion.Body>
                          {question.length > 0 && (
                            <div className="my-3">
                              <b className="mb-3">{HEADING.question}:</b>
                              <form>
                                {question.map((el, index) => (
                                  <QuestionInput
                                    category={category}
                                    value={el}
                                    index={index}
                                    key={index}
                                    imported={imported}
                                    questionTagsValue={questionTagsValue}
                                    tagsValueChange={questionTagsValueChangefun}
                                    questionCategoryValue={
                                      questionCategoryValue?.[index]
                                    }
                                    categoryValueChange={
                                      questionCategoryValueChangefun
                                    }
                                    categoriesFields={categoriesFields}
                                    tagfields={tagfields}
                                    onSaveRef={onQuestionInputSaveRef}
                                    onRemove={onQuestionRemove}
                                    onInputChange={onQuestionInputChange}
                                  />
                                ))}
                                <Button
                                  className="d-flex align-items-center justify-content-center bank"
                                  type="dashed"
                                  onClick={() =>
                                    HandleAddMoreQuestions(category)
                                  }
                                  style={{ width: "40%" }}
                                  icon={<PlusOutlined />}
                                  disabled={!category || !question}
                                >
                                  Add Question
                                </Button>
                              </form>
                            </div>
                          )}
                          <CorrectCategoryOption
                            imported={imported}
                            category={category}
                            index={index}
                            correctMultipleOptionsInputRef={
                              correctMultipleOptionsInputRef
                            }
                            question={question}
                            categoriesFields={categoriesFields}
                            HEADING={HEADING}
                            correctMultipleOptions={correctMultipleOptions}
                            onCorrectMultipleOptionsInputSaveRef={
                              onCorrectMultipleOptionsInputSaveRef
                            }
                            onCorrectMultipleOptionsRemove={
                              onCorrectMultipleOptionsRemove
                            }
                            onCorrectMultipleOptionsInputChange={
                              onCorrectMultipleOptionsInputChange
                            }
                            onCorrectMultipleOptionsInputAdd={
                              onCorrectMultipleOptionsInputAdd
                            }
                            onCorrectMultipleOptionsLoad={
                              onCorrectMultipleOptionsLoad
                            }
                            genderSelect={genderSelect}
                          />
                          <DistractCategoryOption
                            imported={imported}
                            category={category}
                            index={index}
                            answerInputRef={answerInputRef}
                            question={question}
                            categoriesFields={categoriesFields}
                            answers={answers}
                            HEADING={HEADING}
                            onAnswerInputSaveRef={onAnswerInputSaveRef}
                            onAnswerRemove={onAnswerRemove}
                            onAnswersInputChange={onAnswersInputChange}
                            onAnswersInputAdd={onAnswersInputAdd}
                            onAnswersLoad={onAnswersLoad}
                            genderSelect={genderSelect}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  );
                })}

              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  size="large" //TODO
                  icon={<SafetyOutlined />}
                  onClick={HandleEditCase}
                  className="d-flex align-items-center bank generate"
                >
                  <>Save Changes</>
                </Button>
                <Button
                  size="large"
                  icon={<DownloadOutlined />}
                  className="d-lg-flex  d-md-flex align-items-center  d-none bank generate"
                  onClick={() => downloadFile()}
                >
                  Save Case Template
                </Button>
                <Button
                  size="large"
                  icon={<DownloadOutlined />}
                  className="d-flex align-items-center d-lg-none d-md-none d-block bank generate"
                  onClick={() => downloadFile()}
                >
                  Save Template
                </Button>
              </div>
            </>
          )}
        </main>
      )}
    </>
  );
};
