import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { Offcanvas } from "react-bootstrap";
import axios from "../api/axios";
import Cookies from "universal-cookie";
import useAuth from "./logincomponents/hooks/useAuth";
import { useNavigate } from "react-router-dom";

const cookies = new Cookies();
export default function Sidebar() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleToggle = () => {
    setShow(!show);
  };

  const logout = () => {
    axios
      .post(
        "logout/",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("access")}`,
          },
          withCredentials: true,
        }
      )
      .then((res) => {
        setAuth({});
        window.localStorage.setItem("isLoggedIn", "false");
        navigate("/login");
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          console.log(err);
          window.localStorage.setItem("isLoggedIn", "false");
          navigate("/login");
        }
      });
  };

  return (
    <div className="d-lg-none d-md-none d-block">
      <FontAwesomeIcon icon={faBars} id="dots" onClick={handleToggle} />
      <Offcanvas show={show} onHide={handleToggle} placement="end">
        <Offcanvas.Header
          closeButton
          className="d-flex justify-content-end"
        ></Offcanvas.Header>
        <Offcanvas.Body className="text-center mt-4">
          <img
            src="/images/logo-print-hd-transparent-black.png"
            alt=""
            width="267px"
            id="sbLogo"
            style={{ margin: "auto", maxHeight: "201px" }}
          />
          <hr style={{ background: "black" }} className="mt-5" />
          <button id="signOut" className="text-center" onClick={() => logout()}>
            <FontAwesomeIcon
              icon={faRightFromBracket}
              id="rightBracket"
              className="me-2"
            />
            Sign out
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}
