import { createContext } from "react";

const values = {
    state: {
        user: {
            username: "",
            image: "",
        }
    },
    dispatch: (action) => { }
}
export const GlobalContext = createContext(values);
GlobalContext.displayName = "GlobalContext";