import { Tag } from "antd";

const Chip = ({ item, field, onRemove }) => {
    const handleClose = () => {
        onRemove(field, item);
    };
    return (
        <Tag closable={true} onClose={handleClose} className="d-flex align-items-center mb-2" style={{fontSize:"12px"}}> 
            <span style={{wordBreak:"break-word"}}>{item}</span>
        </Tag>
    );
};
export default Chip