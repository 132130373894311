import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import image1 from "./images/new_logo.png";
import image2 from "./images/right_image.webp";
const { Title } = Typography;
const Header = ({ name }) => (
  <header
    className="text-center d-lg-flex align-items-center justify-content-center mb-lg-4 mb-md-4  mt-lg-0 mt-md-0 mt-5"
    id="header-img"
  >
    {/* <div style={{ height: "300px", width: "100%" }}>

        </div> */}
    <div className="col-xl-3 col-lg-3 col-md-12 ">
      <img
        src={image1}
        alt=""
        width="370px"
        // height="auto"
        style={{ margin: "auto", maxHeight: "201px" }}
        id="aig1"
      />
      <p style={{ color: "white" }}>
        The future of question writing in medicine
      </p>
      {/* <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon={faEnvelope} color="#fff" className="pe-2 pt-1" />
        <p style={{ color: "white" }}>issamfrancis@gmail.com</p>
      </div> */}
    </div>
    <div className="col-xl-6 col-lg-6 col-md-12">
      <div className="mt-lg-0 mt-md-0 p-xl-5 p-lg-5 p-md-5 px-0 pb-4 pt-lg-3 pt-md-3 pt-2">
        <Title
          id="title"
          style={{ color: "#fff", borderBottom: "none", padding: 0 }}
        >
          {name}
        </Title>
        <p style={{ color: "#fff" }}>
          Dr. Issam Francis M.D. FRCPath © {new Date().getFullYear()} All Rights
          Reserved
        </p>
      </div>
    </div>
    <div className="col-xl-3 col-lg-3 d-xl-block d-lg-block d-none">
      <img
        src={image2}
        alt=""
        width="auto"
        // height="auto"
        style={{ margin: "auto", maxHeight: "201px" }}
        // className="d-lg-block d-none"
      />
    </div>
  </header>
);
export default Header;
